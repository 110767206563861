<template>
    <div class="filter--main tw-mb-2">
        <div class="filter--wrapper tw-bg-white tw-shadow-md tw-rounded">
            <div class="tw-font-bold tw-mb-2 tw-text-gray-400 tw-flex tw-justify-between">
                <div class="tw-ml-1 tw-mb-2 tw-font-bold" style="color: #7b7e82">
                    {{ filterKey && filterKey.displayAs }}
                </div>
                <div class="tw-mr-1 tw-cursor-pointer">
                    <img v-if="!filterKey" class="transition-transform tw-w-2 tw-h-2 tw-w-1 tw-h-1" style="filter: invert(60%)" :src="require('@/assets/icons/letter-x.svg')" @click="removeFilter" />
                    <img v-if="!hideFilter && filterKey && !collapsed" class="transition-transform tw-w-3 tw-h-3" :src="require('@/assets/icons/graph/caret-down.png')" @click="hideFilter = !hideFilter" />
                    <img v-if="hideFilter && filterKey" class="tw-w-3 tw-h-3 tw-transform tw-rotate-180" :src="require('@/assets/icons/graph/caret-down.png')" @click="hideFilter = !hideFilter" />
                </div>
            </div>

            <div v-if="filterValue" class="tw-mb-2 tw-mt-2 tw-flex tw-justify-between tw-w-4/5 tw-p-1 tw-text-white" style="border-radius: 5px; background-color: #6495ed">
                <div class="tw-ml-1 tw-truncate">
                    {{ filterValue.label }}
                </div>

                <div class="tw-mr-1 tw-cursor-pointer">
                    <img class="tw-w-2 tw-h-2" :src="require('@/assets/icons/letter-x.svg')" style="filter: invert(100%)" @click="removeFilter" />
                </div>
            </div>
            <div :class="hideFilter || collapsed ? 'filter--collapsed' : 'filter--expanded'">
                <!-- <vue-multiselect v-model="filterKey" :options="filterTypesList" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pick filter key" label="label" track-by="id" group-values="categories" group-label="group" :group-select="true"></vue-multiselect> -->
                <!-- {{filterKey}} -->
                <vue-multiselect v-model="filterKey" :options="filterTypes" placeholder="Select Type" :searchable="true" :multiple="false" :checkboxes="true" group-values="categories" group-label="group" :group-select="true" :close-on-select="false" :clear-on-select="true" :show-labels="true" :taggable="true" label="displayAs" track-by="displayAs" class="var(--area-brand-color)" @input="handleTypeChange"> </vue-multiselect>
                <div v-if="filterKey !== null" class="tw-flex tw-mt-2 tw-items-center">
                    <vue-multiselect v-model="filterValue" :options="nodeValsList" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pick a value" label="label" track-by="label" group-values="categories" group-label="group" :group-select="true" @input="addFilterValue"></vue-multiselect>
                    <!-- <img v-if="showAddFilterButton && filterKey && filterValue" class="tw-ml-2 tw-cursor-pointer  tw-w-4 tw-h-4  tw-rounded-sm tw-items-center tw-p-2" @click="addFilter" :src="require('@/assets/icons/graph/plus.png')" style="background:#EEEEEE;border-radius:5px;background:var(--brand-accent-hover-on-dark);" /> -->
                </div>
            </div>
            <div class="connection--level"></div>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "@/components/vue-multiselect";

export default {
    props: ["showAddFilterButton", "filterTypes", "filterValues", "collapsed", "id", "selectedType"],
    components: {
        VueMultiselect,
    },
    name: "osintGraphFilter",
    data: function() {
        return {
            filterKey: null,
            filterValue: null,
            hideFilter: false,
            value: "",
            options: ["f-one", "f-two", "f-three"],
        };
    },
    watch: {
        // filterTypes(newVal){
        //     let temp = this.filterKey
        //     this.filterKey = null
        //     this.filterKey = temp
        // }
    },
    mounted() {
        console.table(this.filterTypesList);
        this.filterKey = this.selectedType.filterKey;
        this.filterValue = this.selectedType.filterVal;
    },
    computed: {
        nodeVals() {
            return this.filterValues.filter((e) => {
                return e.type === this.filterKey.label;
            });
            // return this.filterValues
        },
        filterTypesList() {
            return [
                {
                    group: "Filter Type",
                    categories: this.filterTypes,
                },
            ];
        },
        nodeValsList() {
            return [
                {
                    group: "Filter Value",
                    categories: this.nodeVals,
                },
            ];
        },
    },
    methods: {
        handleTypeChange() {
            this.filterValue = null;
            this.$emit("typeChange", this.filterKey);
        },
        removeFilter() {
            this.$emit("removeFilter");
        },
        addFilterValue() {
            this.$emit("filterValueSelect", {filterKey: this.filterKey, filterVal: this.filterValue});
        },
        addFilter() {
            this.$emit("addGraphFilter");
        },
    },
};
</script>

<style lang="scss">
.filter--collapsed {
    visibility: hidden;
    height: 0;
}

.filter--expanded {
    visibility: visible;
}

.filter--wrapper {
    width: 90%;
    padding: 10px 5px 10px 5px;
    margin: 0 auto;
    border-bottom: solid 1px #eaeaea;
}
</style>
