<template>
    <div class="tw-flex tw-h-full tw-w-full">
        <div class="tw-z-10 tw-flex tw-absolute tw-top-0 tw-right-4">
            <div class="tw-flex tw-items-center tw-p-2 tw-mr-1 tw-bg-gray-200 tw-cursor-pointer">
                <img class="tw-h-4" v-if="true" @click="StabilizeGraph" src="@/assets/icons/ico-refresh.svg" height="14px" v-bind:class="{spin: stabilizing}" />
            </div>
            <div v-if="!readOnly">
                <button v-show="!expandedGraph" class="tw-bg-gray-200 tw-border-0 tw-p-2 tw-mr-1 tw-cursor-pointer" @click="addActiveToolToPersistedFiltersList">
                    Add to graph
                </button>
                <button v-show="!expandedGraph" class="tw-bg-gray-200 tw-border-0 tw-p-2 tw-cursor-pointer" @click="removeActiveToolToPersistedFiltersList">
                    Remove from graph
                </button>
            </div>
        </div>
        <div class="osint--main tw-flex">
            <div v-show="showOptionsPanel && expandedGraph" class="tw-relative tw-flex tw-flex-col graph--options tw-w-72 tw-pr-2 tw-overflow-x-hidden">
                <div class="tw-py-2 tw-px-1 tw-flex tw-items-center tw-text-base">
                    <span class="tw-text-xs tw-ml-1 tw-font-semibold">FILTERS</span>
                </div>
                <div v-if="showLoader || fetchingCaseData" class="tw-flex tw-flex-col tw-rounded-lg tw-py-4 tw-px-2 tw-mx-1 tw-shadow-md tw-bg-gray-100 tw-h-full tw-absolute tw-z-10 tw-w-full tw-opacity-75"></div>
                <div class="tw-flex tw-flex-col tw-rounded-lg tw-py-4 tw-px-2 tw-mx-1 tw-bg-white tw-shadow-md">
                    <div class="tw-flex tw-gap-x-2 tw-mb-2 tw-pb-4" style="border-bottom: solid 1px rgb(210 210 210)">
                        <button class="tw-px-4 tw-flex-grow tw-text-sm tw-text-gray-700 tw-border-0 hover:tw-bg-blue-100 hover:tw-text-brand tw-py-2 tw-rounded tw-cursor-pointer" :class="{active: isCaseDataToggleActive}" @click="onToggleCaseData">
                            Case Data
                        </button>
                        <button class="tw-px-4 tw-text-sm tw-flex-grow tw-border-0 tw-text-gray-700 hover:tw-bg-blue-100 hover:tw-text-brand tw-py-2 tw-rounded tw-cursor-pointer" :class="{active: isToolDataToggleActive}" @click="onToggleToolData">
                            Tool Data
                        </button>
                    </div>
                    <!-- <div class="tw-mb-4 tw-pb-4" style="border-bottom: solid 1px rgb(210 210 210)">
                        <div class="tw-text-sm tw-text-gray-500 tw-flex tw-justify-between tw-cursor-pointer" :class="toolType.length > 0 ? ['highlight--filters'] : ''">
                            <div class="tw-mb-2">Tools</div>
                            <div v-if="toolType.length > 0" class="restore--icon tw-text-xs">
                                <span @click="emptyToolTypeFilter">Clear</span>
                            </div>
                        </div>
                        <vue-multiselect v-model="toolType" :options="toolsList" placeholder="Select tool" :searchable="false" :disabled="isToolFilterDisabled || isShortestPathSelected" :multiple="true" :checkboxes="true" group-values="categories" group-label="group" :group-select="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" :limit="1" :taggable="true" label="id" track-by="id" @select="onToolTypeChange" @input="executeFilter" class="var(--area-brand-color)">
                        </vue-multiselect>
                    </div> -->
                    <div class="tw-flex tw-justify-center tw-flex-col tw-pb-4 tw-mb-4" style="border-bottom: solid 1px rgb(210 210 210)">
                        <!-- <div class="tw-text-sm tw-text-gray-500 tw-flex tw-justify-between tw-cursor-pointer tw-mb-1" :class="possibleSearchTypes.length > 0 ? ['highlight--filters'] : ''">
                            <div class="tw-mb-2">Type &amp; Value</div>
                            <div v-if="possibleSearchTypes.length > 0" class="restore--icon tw-text-xs">
                                <span @click="emptySearchTypeFilter">Clear</span>
                            </div>
                        </div> -->

                        <!-- <vue-multiselect v-model="possibleSearchTypes" :options="searchTypesList" placeholder="Select Type" :searchable="true" :disabled="isIndependentFiltersDisabled || isShortestPathSelected" :multiple="true" :checkboxes="true" group-values="categories" group-label="group" :group-select="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" :limit="1" :taggable="true" label="label" track-by="id" @input="executeFilter" class="var(--area-brand-color)">
                        </vue-multiselect> -->
                        <!--
                        <button class="add--type--filter--value tw-px-3 tw-mt-2 tw-py-3 tw-rounded tw-border-0 tw-text-white tw-border-1 tw-cursor-pointer tw-w-full" style="background: var(--brand-color)" @click="addGraphFilter">Add Filter</button> -->
                        <button class="add--type--filter--value tw-px-3 tw-mt-4 tw-py-3 tw-rounded tw-border-0 tw-text-white tw-border-1 tw-cursor-pointer tw-w-full" style="background: var(--brand-color)" :class="{disabled: isAddValueButtonDisabled}" @click="addGraphFilter">
                            Add Filter
                        </button>
                    </div>
                    <div class="tw-mb-4 tw-pb-4" style="border-bottom: solid 1px rgb(210 210 210)">
                        <div class="tw-text-sm tw-text-gray-500 tw-flex tw-justify-between tw-cursor-pointer" :class="toolType.length > 0 ? ['highlight--filters'] : ''">
                            <div class="tw-mb-2">Tools</div>
                            <div v-if="toolType.length > 0" class="restore--icon tw-text-xs">
                                <span @click="emptyToolTypeFilter">Clear</span>
                            </div>
                        </div>
                        <vue-multiselect v-model="toolType" :options="toolsList" placeholder="Select tool" :searchable="false" :disabled="isToolFilterDisabled || isShortestPathSelected" :multiple="true" :checkboxes="true" group-values="categories" group-label="group" :group-select="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" :limit="1" :taggable="true" label="id" track-by="id" @select="onToolTypeChange" @input="executeFilter" class="var(--area-brand-color)">
                        </vue-multiselect>
                    </div>
                    <!-- <div class="tw-mt-4 tw-text-xs tw-ml-1 tw-font-semibold tw-px-1 tw-mb-2">TOOLS</div>
                    <div class="tw-flex tw-flex-col tw-rounded-lg tw-px-2 tw-py-4 tw-mx-1 tw-bg-white tw-shadow-md">
                        <div class="tw-mb-4 tw-pb-4">
                            <div class="tw-text-sm tw-text-gray-500 tw-flex tw-justify-between tw-cursor-pointer" :class="toolType.length > 0 ? ['highlight--filters'] : ''">
                                <div class="tw-mb-2">Select tools</div>
                                <div v-if="toolType.length > 0" class="restore--icon tw-text-xs">
                                    <span @click="emptyToolTypeFilter">Clear</span>
                                </div>
                            </div>
                            <vue-multiselect v-model="toolType" :options="toolsList" placeholder="Select tool" :searchable="false" :disabled="isToolFilterDisabled || isShortestPathSelected" :multiple="true" :checkboxes="true" group-values="categories" group-label="group" :group-select="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" :limit="1" :taggable="true" label="id" track-by="id" @select="onToolTypeChange" @input="executeFilter" class="var(--area-brand-color)">
                        </vue-multiselect>
                    </div>
                </div> -->
                    <div class="tw-text-sm tw-mb-1 tw-text-gray-500" :class="nodeConnectionCount ? ['highlight--filters'] : ''">
                        Connection counts
                    </div>
                    <div class="tw-flex">
                        <vue-multiselect v-model="nodeConnectionCount" :options="connectionCountOptions" placeholder="Select connection count" :searchable="false" :disabled="isIndependentFiltersDisabled || isShortestPathSelected" :multiple="false" :checkboxes="true" group-values="categories" group-label="group" :group-select="false" :close-on-select="true" :clear-on-select="true" :show-labels="false" label="label" track-by="id" @input="onSelectConnectionCount"> </vue-multiselect>
                    </div>
                </div>
                <div class="tw-mt-4 tw-text-xs tw-ml-1 tw-font-semibold tw-px-1 tw-mb-2">LAYOUT</div>
                <div class="tw-flex tw-flex-col tw-rounded-lg tw-px-2 tw-py-4 tw-mx-1 tw-bg-white tw-shadow-md">
                    <!-- <div class="tw-flex tw-mb-4 tw-justify-between" style="border-bottom:solid 1px rgb(210 210 210);">
                        <div
                            class="tw-flex tw-flex-wrap tw-w-full tw-justify-between tw-items-center tw-h-10 tw-pb-6 tw-px-2 tw-rounded-md">
                            <font-awesome-icon
                                class="tw-shadow tw-w-4 tw-h-4 tw-cursor-pointer tw-focus-bg-red-200 tw-p-3 tw-rounded-lg hover:tw-bg-blue-500 hover:tw-text-white"
                                :class="layout === 'UD' ? ['tw-bg-blue-500', 'tw-text-white'] : ['tw-bg-white']"
                                icon="sitemap" @click="setHierarchy('UD')"></font-awesome-icon>
                            <font-awesome-icon
                                class="tw-shadow tw-transform tw--rotate-90 tw-w-4 tw-h-4 tw-cursor-pointer tw-focus-bg-red-200 tw-p-3 tw-rounded-lg hover:tw-bg-blue-500 hover:tw-text-white"
                                :class="layout === 'LR' ? ['tw-bg-blue-500', 'tw-text-white'] : ['tw-bg-white']"
                                icon="sitemap" @click="setHierarchy('LR')"></font-awesome-icon>
                            <font-awesome-icon
                                class="tw-shadow tw-transform tw--rotate-180 tw-w-4 tw-h-4 tw-cursor-pointer tw-focus-bg-red-200 tw-p-3 tw-rounded-lg hover:tw-bg-blue-500 hover:tw-text-white"
                                :class="layout === 'DU' ? ['tw-bg-blue-500', 'tw-text-white'] : ['tw-bg-white']"
                                icon="sitemap" @click="setHierarchy('DU')"></font-awesome-icon>
                            <font-awesome-icon
                                class="tw-shadow tw-transform  tw-rotate-90 tw-w-4 tw-h-4 tw-cursor-pointer tw-focus-bg-red-200 tw-p-3 tw-rounded-lg hover:tw-bg-blue-500 hover:tw-text-white"
                                :class="layout === 'RL' ? ['tw-bg-blue-500', 'tw-text-white'] : ['tw-bg-white']"
                                icon="sitemap" @click="setHierarchy('RL')"></font-awesome-icon>
                            <font-awesome-icon
                                class="tw-shadow tw-transform tw-rotate-90 tw-w-4 tw-h-4 tw-cursor-pointer tw-focus-bg-red-200 tw-p-3 tw-rounded-lg hover:tw-bg-blue-500 hover:tw-text-white"
                                :class="layout === null ? ['tw-bg-blue-500', 'tw-text-white'] : ['tw-bg-white']"
                                icon="project-diagram" @click="setRandomSeed"></font-awesome-icon>
                        </div>
                    </div> -->
                    <div v-show="possibleSearchTypes.length > 0" class="tw-mb-4 tw-pb-4" style="border-bottom: solid 1px rgb(210 210 210)">
                        <div class="tw-text-sm tw-mb-1" :class="nodeDegree ? ['highlight--filters'] : ''">
                            Connection degree
                        </div>
                        <div class="tw-flex">
                            <vue-multiselect v-model="nodeDegree" :options="nodeDegreeOptions" placeholder="Select connection degree" :searchable="false" :multiple="false" :checkboxes="true" group-values="categories" group-label="group" :group-select="false" :close-on-select="true" :clear-on-select="true" :show-labels="false" @input="onSelectConnectionDegree"> </vue-multiselect>
                        </div>
                    </div>
                    <!-- <div class="tw-flex tw-justify-center tw-flex-col tw-mb-4 tw-pb-4"
                        style="border-bottom:solid 1px rgb(210 210 210);">
                        <div class="tw-text-sm tw-text-gray-500 tw-flex tw-justify-between tw-cursor-pointer tw-mb-1"
                            :class="focusNode ? ['highlight--filters'] : ''">
                            <div class="">
                                Focus node
                            </div>
                            <div v-if="focusNode" class="restore--icon tw-text-xs">
                                <span @click="fitGraphToCenter">Clear</span>
                            </div>
                        </div>

                        <div class="tw-mt-2">
                            <vue-multiselect v-model="focusNode" :options="getActiveNodesList" placeholder="Filter by value"
                                :searchable="true" :multiple="false" :checkboxes="true" group-values="categories"
                                group-label="group" :group-select="false" :close-on-select="true" :clear-on-select="true"
                                :show-labels="false" :taggable="true" label="label" track-by="id" @tag=""
                                @input="focusOnNode" class="var(--area-brand-color)"> </vue-multiselect>
                        </div>
                    </div> -->

                    <div class="tw-flex tw-justify-center tw-flex-col">
                        <div class="tw-text-sm tw-text-gray-500 tw-flex tw-justify-between tw-cursor-pointer tw-mb-1" :class="shortestPathNodeOne && shortestPathNodeTwo ? ['highlight--filters'] : ''">
                            <div class="">Shortest link between nodes</div>
                            <div v-if="shortestPathNodeOne || shortestPathNodeTwo" class="restore--icon tw-text-xs">
                                <span @click="emptyShortestLinkFields">Clear</span>
                            </div>
                        </div>
                        <div class="tw-mt-2">
                            <vue-multiselect v-model="shortestPathNodeOne" :options="getValuesAfterFiltersList" placeholder="Filter by value" :searchable="true" tag-placeholder="Add this as filter" :multiple="false" :checkboxes="true" group-values="categories" group-label="group" :group-select="false" :close-on-select="true" :clear-on-select="true" :show-labels="false" :taggable="true" label="label" track-by="id" @input="shortestLink" class="var(--area-brand-color)"> </vue-multiselect>
                        </div>
                        <div class="tw-mt-2">
                            <vue-multiselect v-model="shortestPathNodeTwo" :options="getValuesAfterFiltersList" placeholder="Filter by value" :searchable="true" tag-placeholder="Add this as filter" :multiple="false" :checkboxes="true" group-values="categories" group-label="group" :group-select="false" :close-on-select="true" :clear-on-select="true" :show-labels="false" :taggable="true" label="label" track-by="id" @input="shortestLink" class="var(--area-brand-color)"> </vue-multiselect>
                        </div>

                        <!-- <div v-if="showFiltersRestoreButton" class="tw-mt-2">
                            <neo-button label="Restore filters" :bg="'var(--brand-color)'" :color="'white'" hoverBg="var(--brand-color)" hoverText="white" :style="{ width: '100%' }" padding="0.5rem 0.5rem" @click="applyStoredFilters" borderRadius="0.5rem"></neo-button>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="tw-mt-4 tw-text-xs tw-ml-1 tw-font-semibold tw-px-1 tw-mb-2">TOOLS</div>
                <div class="tw-flex tw-flex-col tw-rounded-lg tw-px-2 tw-py-4 tw-mx-1 tw-bg-white tw-shadow-md">
                    <div class="tw-mb-4 tw-pb-4">
                        <div class="tw-text-sm tw-text-gray-500 tw-flex tw-justify-between tw-cursor-pointer" :class="toolType.length > 0 ? ['highlight--filters'] : ''">
                            <div class="tw-mb-2">Select tools</div>
                            <div v-if="toolType.length > 0" class="restore--icon tw-text-xs">
                                <span @click="emptyToolTypeFilter">Clear</span>
                            </div>
                        </div>
                        <vue-multiselect v-model="toolType" :options="toolsList" placeholder="Select tool" :searchable="false" :disabled="isToolFilterDisabled || isShortestPathSelected" :multiple="true" :checkboxes="true" group-values="categories" group-label="group" :group-select="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" :limit="1" :taggable="true" label="id" track-by="id" @select="onToolTypeChange" @input="executeFilter" class="var(--area-brand-color)">
                        </vue-multiselect>
                    </div>
                </div> -->
            </div>
            <div v-if="graphFilters.length > 0 && expandedGraph" class="graph--options tw-overflow-auto tw-w-60 tw-h-full" style="transition: all 500ms">
                <div class="tw-font-medium tw-mt-2 tw-flex tw-mb-2 tw-justify-between tw-items-center tw-pl-2 tw-pr-2">
                    <div class="tw-text-xs tw-ml-2 tw-font-semibold tw-text-gray-600">VALUE FILTERS</div>
                    <span class="tw-text-xs tw-mr-1 tw-font tw-text-blue-500 tw-cursor-pointer tw-font" @click="collapseAllFilters">{{ getFilterActionLable }}</span>

                    <!-- <img v-if="collapseAll === false" :src="require('@/assets/icons/graph/minus-sign.png')" class="tw-w-2 tw-h-2 tw-cursor-pointer tw-rounded-sm tw-p-1 tw-bg-gray-200" @click="collapseAllFilters" alt="" />
                    <img v-if="collapseAll === true" :src="require('@/assets/icons/graph/plus.png')" class="tw-w-2 tw-h-2 tw-cursor-pointer tw-rounded-sm tw-p-1 tw-bg-gray-200" @click="collapseAllFilters" alt="" /> -->
                </div>
                <osintGraphFilter @typeChange="onTypeChange(index, $event)" v-for="(filter, index) in graphFilters" :key="filter.id" :collapsed="shouldCollapseValueFilter(filter)" :filterTypes="searchTypesList" :selectedType="filter" :filterValues="getFilterValues" @removeFilter="onRemoveFilter(index)" @filterValueSelect="onFilterValueSelect(index, $event)" />

                <div class="add--filter"></div>
            </div>
            <!-- <div class="network--wrapper tw-w-full" style="position: relative;">
                <div class="cn-wrapper" id="cn-wrapper" style="position: absolute;">
                    <ul>
                        <li>
                            <a href="#"
                                ><span class="icon-picture"> <img class="tw-w-6 tw-h-6" :src="require('@/assets/icons/graph/color-picker.png')" alt="" @click="openColorPicker" /> </span
                            ></a>
                        </li>

                        <li>
                            <a href="#"
                                ><span class="icon-picture"> <img class="tw-w-6 tw-h-6" :src="require('@/assets/icons/graph/network.png')" alt="" @click="clearGraphOnCanvas" /> </span
                            ></a>
                        </li>

                        <li>
                            <a href="#"
                                ><span class="icon-headphones"> <img class="tw-w-6 tw-h-6" :src="require('@/assets/icons/graph/settings.png')" alt="" /> </span
                            ></a>
                        </li>
                        <li>
                            <a href="#"
                                ><span class="icon-home">
                                    <img class="tw-w-6 tw-h-6" :src="require('@/assets/icons/graph/eye.png')" alt="" @click="collapseGraphNodes" />
                                </span>
                            </a>
                        </li>
                        <li @click="closeGraphPanel">
                            <a href="#"
                                ><span class="icon-facetime-video">
                                    <img
                                        class="tw-w-6 tw-h-6"
                                        :src="require('@/assets/icons/graph/close.png')"
                                        alt=""
                                        style="transform: rotate(85deg);
                      width: 15px;
                      height: 15px;"
                                    /> </span
                            ></a>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>
        <div class="tw-flex tw-relative tw-h-full tw-w-full">
            <div v-if="!layoutDone || !isGraphDataQueueEmpty" class="bg-drop tw-flex tw-justify-center tw-items-center tw-bg-white tw-w-full tw-h-full tw-absolute tw-z-20">
                <div class="">Loading...</div>
            </div>
            <KlChart style="height: 100%; width: 100%" :id="`kl_${new Date()}`" containerClass="klchart" :data="keylineData" @kl-ready="klReady" />
        </div>
        <analyse ref="analyze-popup" :source="selectedSource" :fromGraph="true" :value="selectedValue" v-show="showSmartAnalyser" />
    </div>
</template>

<script>
import "keylines";
import VueMultiselect from "@/components/vue-multiselect";
import {uniqueId} from "lodash";

import KlChart from "@/components/Chart";
import osintGraphFilter from "./osintGraphFilter";
import Template from "../../views/report-builder/template.vue";
import analyse from "@/components/analyse";
import {JURISDICTION_MAP} from "../../utils/constants";

export const colours = {
    time: "rgb(255,153,0)",
    distance: "rgb(64,77,254)",
    equal: "rgb(64,77,254)",
};

export default {
    name: "KlGraph",
    components: {KlChart, VueMultiselect, osintGraphFilter, analyse},

    props: ["graphNodes", "graphLinks", "fetchingCaseData", "expandedGraph", "showOptionsPanel", "activeToolSection", "nodeTypeKeyMap", "caseData", "isGraphDataQueueEmpty", "readOnly"],
    watch: {
        activeToolSection(newVal, oldVal) {
            this.onToolChange(newVal);
        },
        graphNodes: {
            deep: true,
            handler(newvalue, oldvalue) {
                this.updateNodes(newvalue);
            },
        },
        graphLinks(newvalue, oldvalue) {
            this.updateLinks(newvalue);
        },
        caseData(newvalue, oldvalue) {
            if (newvalue) {
                this.executeFilter().then(() => {
                    this.StabilizeGraph();
                });
            }
        },
    },
    mounted() {
        this.imageMap();
        if (this.activeToolSection) {
            this.toggleToolData = true;
            var id = this.activeToolSection;
            if (this.activeToolSection === "personIdentifier") {
                id = "Person identifier";
            } else {
                id = this.removeHyphenAndTitlecase(this.activeToolSection);
            }

            this.toolType.push({id: id, label: this.activeToolSection});
        }
        // if (this.isGraphDataQueueEmpty){
        //     this.StabilizeGraph();
        // }
    },

    computed: {
        getFilterActionLable() {
            var text = "collapse filters";
            if (this.collapseAll) {
                text = "expand filters";
            }
            return text;
        },
        isShortestPathSelected() {
            return this.shortestPathNodeOne !== null && this.shortestPathNodeTwo !== null;
        },
        iconCache() {
            let iconData = {};
            let icons = [...this.$store.getters.getIconsData, ...this.$store.getters.getSocialPlatforms];
            for (let i = 0; i < icons.length; i++) {
                iconData[icons[i].key] = icons[i].icon_data;
            }
            return iconData;
        },
        isCaseDataToggleActive() {
            return this.toggleCaseData;
        },
        isToolDataToggleActive() {
            return this.toggleToolData;
        },

        isIndependentFiltersDisabled() {
            return !(this.toggleToolData || this.toggleCaseData);
        },

        // isToolFilterDisabled() {
        //   const c1 = !this.toggleToolData
        //   const c2 = this.persistToolFilters.length === 0
        //   return c1 && c2
        // },

        isToolFilterDisabled() {
            return !this.toggleToolData;
        },

        isTypesDisabled() {
            return this.toolType.length === 0;
        },

        isAddValueButtonDisabled() {
            const c1 = !this.toggleToolData;
            const c2 = !this.toggleCaseData;
            return c1 && c2;
        },

        getFilterValues() {
            let fv = [];
            // NOTE: (KSHITIJ) Check later why duplicates in the first place?
            let unique = new Set();
            fv = this.graphNodes.filter((n) => {
                const f1 = this.valuesFromfilters["typeFilter"].includes(n.id);
                if (f1) {
                    const indexInAppliedFilters = this.graphFilters.findIndex((o) => {
                        return o.filterVal.label === n.label;
                    });
                    if (indexInAppliedFilters < 0 && !unique.has(n.id)) {
                        unique.add(n.id);
                        return true;
                    }
                    return false;
                }
                return false;
            });
            return fv;
        },

        tools() {
            let tools = [];
            const toolsMapper = {
                company: "corporate Records",
                personIdentifier: "person identifier",
                domain: "company domain",
            };
            for (let n of this.graphNodes) {
                for (let source of n.source) {
                    if (!tools.includes(source) && source !== undefined && source !== "caseData") tools.push(source);
                }
            }
            return tools.map((e) => {
                var id = e;
                if (toolsMapper[e] !== undefined) {
                    var id = toolsMapper[e];
                }
                id = this.removeHyphenAndTitlecase(id);
                return {id: id, label: e};
            });
            // return [...new Set(this.graphNodes.map((n) => n.source).flatMap(n=>n).filter((n) => n !== undefined))].map((n) => ({ id: n, label: n }));
        },

        isFilterPanelExpaned() {
            return this.graphFilters.length >= 1;
        },
        showGraphSaveButton() {
            return this.graphFilters.nodeValues.length <= 0 && this.graphFilters.entityValues.length <= 0 && this.graphFilters.sourceFilter.length <= 0;
        },
        // sources() {
        //     let vals = [];
        //     let seen = [];
        //     this.graphNodes.forEach((n, idx) => {
        //         if (!(n.source in seen) && n.source !== undefined) {
        //             vals.push({ label: n.source, id: idx });
        //             seen[n.source] = true;
        //         }
        //     });
        //     return vals;
        // },
        filterTypes() {
            // return this.filters.filter(e => {
            //   return e.active === true
            // })
            return this.possibleSearchTypes;
        },
        filterValues() {
            // return this.nodeValues
            return this.getActiveNodes;
        },

        searchTypes() {
            // if (this.toolType === null) return [];
            let vals = [];
            let seen = {};
            const TypeMapper = {
                multi_query_center_node: "Search Query",
            };

            let nodesSet = [];
            if (this.toggleCaseData) {
                nodesSet = nodesSet.concat(this.getCaseDataNodes());
            }
            if (this.toggleToolData) {
                nodesSet = nodesSet.concat(this.getToolDataNodes());
            }
            // const toolFilters = this.toolType.map(e => e.label)
            // let activeNodes = this.graphNodes.filter(n => toolFilters.includes(n.source));
            nodesSet.forEach((n) => {
                var type = n.type;
                if (!(type in seen) && type !== undefined) {
                    var displayAs = type;
                    if (TypeMapper[type] !== undefined) {
                        displayAs = TypeMapper[type];
                    }

                    displayAs = this.removeHyphenAndTitlecase(displayAs);
                    vals.push({label: type, id: n.id, displayAs: displayAs});
                    seen[type] = true;
                }
            });
            return vals;
        },

        toolsList() {
            return [{group: "Tools", categories: this.tools}];
        },

        searchTypesList() {
            return [{group: "Search Types", categories: this.searchTypes}];
        },

        getActiveNodesList() {
            return [{group: "Active Nodes", categories: this.getActiveNodes}];
        },

        getValuesAfterFiltersList() {
            return [{group: "Shortest Path Node", categories: this.getValuesAfterFilters}];
        },

        getValuesAfterFilters() {
            let nodesSet = [];
            //NOTE:(Kshitij): Replace this abomination of a code with a state machine!!
            if (this.toggleToolData && this.toggleCaseData && this.possibleSearchTypes.length === 0 && this.toolType.length === 0) {
                nodesSet = this.highlightedNodes;
            } else if (this.toggleToolData && this.toggleCaseData) {
                if (this.possibleSearchTypes.length > 0 && this.graphFilters.length > 0) {
                    nodesSet = this.filteredNodes;
                } else if (this.possibleSearchTypes.length > 0 && this.graphFilters.length === 0) {
                    nodesSet = this.highlightedNodes;
                } else if (this.toolType.length > 0 && this.possibleSearchTypes.length === 0) {
                    nodesSet = this.highlightedNodes;
                }
            } else if (this.graphFilters.length > 0) {
                nodesSet = this.filteredNodes;
            } else if (this.toggleCaseData && !this.toggleToolData && this.possibleSearchTypes.length === 0) {
                nodesSet = this.getCaseDataNodes().map((e) => e.id);
            } else if (this.toggleCaseData && !this.toggleToolData && this.possibleSearchTypes.length > 0) {
                if (this.possibleSearchTypes.length > 0 && this.graphFilters.length > 0) {
                    nodesSet = this.filteredNodes;
                } else if (this.possibleSearchTypes.length > 0 && this.graphFilters.length === 0) {
                    if (this.filteredNodes.length > 0) {
                        nodesSet = this.filteredNodes;
                    } else {
                        nodesSet = this.valuesFromfilters["typeFilter"];
                    }
                }
            } else if (this.toggleCaseData && this.toggleToolData) {
                if (this.possibleSearchTypes.length > 0 && this.graphFilters.length > 0) {
                    nodesSet = this.filteredNodes;
                } else if (this.possibleSearchTypes.length > 0 && this.graphFilters.length === 0) {
                    nodesSet = this.highlightedNodes;
                }
            } else if (this.toggleCaseData) {
                if (this.possibleSearchTypes.length > 0 && this.graphFilters.length > 0) {
                    nodesSet = this.filteredNodes;
                } else if (this.possibleSearchTypes.length > 0 && this.graphFilters.length === 0) {
                    nodesSet = this.highlightedNodes;
                }
            } else if (this.toggleToolData) {
                if (this.possibleSearchTypes.length > 0 && this.graphFilters.length === 0) {
                    if (this.filteredNodes.length > 0) {
                        nodesSet = this.filteredNodes;
                    } else {
                        nodesSet = this.valuesFromfilters["typeFilter"];
                    }
                } else if (this.toolType.length > 0) {
                    nodesSet = this.highlightedNodes;
                }
            }
            // else if(this.toggleCaseData & this.possibleSearchTypes.length > 0)
            // {
            //     nodesSet = this.highlightedNodes
            // }
            let uniqueSet = new Set();
            let arr = [];
            for (let n of this.graphNodes) {
                if (
                    nodesSet.includes(n.id) &&
                    /*NOTE: (Kshitij) check why duplicates in the first place?.*/
                    !uniqueSet.has(n.id)
                ) {
                    uniqueSet.add(n.id);
                    arr.push(n);
                }
            }
            return arr;
        },
    },
    methods: {
        removeHyphenAndTitlecase(type) {
            var text = type.split("-").join(" ");
            text = text.split("_").join(" ");
            if (text.length >= 1) {
                text = text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
                return text;
            }
            return type;
        },
        async onTypeChange(index, filterKey) {
            if (filterKey === null) {
                this.graphFilters[index]["filterVal"] = "";
                this.graphFilters[index]["filterKey"] = filterKey;
                this.possibleSearchTypes.splice(index, 1);
            } else {
                if (this.possibleSearchTypes.length > 0 && index <= this.possibleSearchTypes.length - 1) {
                    this.possibleSearchTypes[index] = filterKey;
                    this.graphFilters[index]["filterVal"] = "";
                } else {
                    this.possibleSearchTypes.push(filterKey);
                }
                this.graphFilters[index]["filterKey"] = filterKey;
            }
            await this.executeFilter();
        },
        async onToolTypeChange() {
            await this.executeFilter();
            await this.StabilizeGraph();
        },
        async onSelectConnectionCount() {
            this.nodeDegree = null;
            await this.executeFilter();
        },
        async onSelectConnectionDegree() {
            this.nodeConnectionCount = null;
            await this.executeFilter();
        },
        addActiveToolToPersistedFiltersList() {
            this.persistToolFilters.push({id: this.activeToolSection, label: this.activeToolSection});
            this.$toast.success("Added to graph");
        },
        removeActiveToolToPersistedFiltersList() {
            const index = this.persistToolFilters.findIndex((e) => e.label === this.activeToolSection);
            if (index !== -1) {
                this.persistToolFilters.splice(index, 1);
                this.$toast.success("Removed From graph");
            }
        },

        /** The tool that was selected in the tools panel is paased
         * as `activeToolSelection` prop to the `Klgraph` component,
         * for legacy reasons value of activeToolSelection is not
         * same as tool name &  can't be changed without potentially,
         * introducing many bugs in existing tools, to map that, this
         * function acts as a source of correction for tool names which
         *  are wrong.
         *
         * @param {string} tool - active selected tool identifer ,
         * need not match the current selected tool name.
         *
         * @returns {string} - Corrected tool name.
         */
        mapActiveToolToCorrectProductName(tool) {
            var correctedToolName = tool;
            switch (tool) {
                case "personIdentifier":
                    correctedToolName = "person identifier";
                    break;
                case "domain":
                    correctedToolName = "company domain";
                    break;
                case "company":
                    correctedToolName = "corporate records";
            }
            var toolName = this.removeHyphenAndTitlecase(correctedToolName);
            return toolName;
        },

        async onToolChange(tool) {
            this.toggleToolData = true;
            if (!tool) return;
            let filters = this.persistToolFilters;

            var id = this.mapActiveToolToCorrectProductName(tool);

            let activeFilter = [{id: id, label: tool}];
            if (!filters.map((e) => e.label).includes(tool)) filters = filters.concat(activeFilter);
            this.toolType = filters;
            if (filters.length > 0) {
                this.toggleToolData = true;
            }
            await this.executeFilter();
            await this.StabilizeGraph();
        },
        async StabilizeGraph() {
            this.layoutDone = false;
            this.stabilizing = true;
            await this.chart.layout("organic", {tightness: 3});
            this.stabilizing = false;
            // this.layoutDone = true;
        },

        getToolDataNodes() {
            let toolNodes = [];
            for (const n of this.graphNodes) {
                const isOnlyCaseData = n.source.length === 1 && n.source.includes("caseData");
                if (!isOnlyCaseData) {
                    toolNodes.push(n);
                }
            }
            return toolNodes;
        },

        getCaseDataNodes() {
            return this.graphNodes.filter((e) => e.source.includes("caseData"));
        },

        /**
         *
         * @param {str} type - unhide given type from graph
         */
        async unhide() {
            let arr = [];
            let config = {hidden: false};

            let nodesToUnhide = [];

            if (this.toggleToolData) {
                let toolNodes = this.getToolDataNodes();
                nodesToUnhide = nodesToUnhide.concat(toolNodes);
            }
            // if (this.toggleCaseData) {
            //     let caseNodes = this.getCaseDataNodes();
            //     nodesToUnhide = nodesToUnhide.concat(caseNodes);
            // }

            for (let n of nodesToUnhide) {
                // var obj = {};
                // Object.assign(obj, config);
                // obj.id = n.id;
                // arr.push(obj);
                arr.push(n.id);
            }
            await this.chart.show(arr, true);
            // window.networkx.body.data.nodes.update(arr);
        },
        resetAllFilters() {
            let filters = {};

            filters.toolType = this.toolType;
            filters.possibleSearchTypes = this.possibleSearchTypes;
            filters.graphFilters = this.graphFilters;

            this.toolType = [];
            this.possibleSearchTypes = [];
            this.focusNode = null;
            this.savedFilters = filters;
            return filters;
        },

        clearGraphOnCanvas() {
            this.hideGraphPopup();
            this.resetAllFilters();
            this.hideAllNodes();
            this.shortestPathNodeOne = null;
            this.shortestPathNodeTwo = null;
            this.graphFilters = [];
        },
        hideAllNodes() {
            let arr = [];
            let hideConfig = {hidden: true};
            for (let n of this.graphNodes) {
                var obj = {};
                Object.assign(obj, hideConfig);
                obj.id = n.id;
                arr.push(obj);
            }
            // window.networkx.body.data.nodes.update(arr);
            this.highlightedNodes = [];
        },

        isSomeFiltersApplied() {
            // let tools = this.toolType;
            let entityType = this.possibleSearchTypes;
            let connectionCount = this.nodeConnectionCount;
            // let caseData = this.toggleCaseData;

            if (
                // tools.length === 0 &&
                entityType.length === 0 &&
                !connectionCount
                // && caseData === false
            ) {
                return false;
            }
            return true;
        },
        async hideCaseData() {
            let arr = [];
            for (const node of this.graphNodes) {
                if (node.source.length === 1 && node.source.includes("caseData")) {
                    arr.push(node.id);
                }
            }

            this.highlightedNodes = this.highlightedNodes.filter((el) => !arr.includes(el));
            await this.chart.hide(arr);
        },
        async unhideCaseData() {
            //NOTE(Ashish) : ashish is supposed to fix this.
            let arr = [];
            let caseNodes = this.getCaseDataNodes();
            for (let n of caseNodes) {
                arr.push(n.id);
                const highlighted = this.highlightedNodes.find((el) => el === n.id);
                if (!highlighted) {
                    this.highlightedNodes.push(n.id);
                }
            }

            await this.chart.show(arr, true); // true to show edges connected to given nodes
        },
        getNodeAttrById(nid, attr) {
            // return window.networkx.body.data.nodes.get(nid)[attr];
            if (!this.chart.getItem(nid)) {
            }
            return this.chart.getItem(nid)[attr];
        },

        async resetHighlightedNodes() {
            var reset_arr = [];
            // var resetFadedNodeConfig = { opacity: 1.0, borderWidth: 0, font: { color: "black" } };
            let resetFadedNodeConfig = {bg: false};
            for (let n of this.highlightedNodes) {
                var obj = {};
                Object.assign(obj, resetFadedNodeConfig);
                obj.id = n;
                reset_arr.push(obj);
            }
            await this.chart.setProperties(reset_arr);
            // window.networkx.body.data.nodes.update(reset_arr);
        },
        async resetHighAllNodes() {
            var reset_arr = [];
            // var resetFadedNodeConfig = { opacity: 1.0, borderWidth: 0, font: { color: "black" } };
            let nodesToReset = [];
            for (let n of this.graphNodes) {
                // var obj = {};
                // Object.assign(obj, resetFadedNodeConfig);
                // obj.id = n.id;
                // reset_arr.push(obj);
                nodesToReset.push(n.id);
            }
            await this.chart.foreground((node) => nodesToReset.includes(node.id));
            // window.networkx.body.data.nodes.update(reset_arr);
        },
        async hideAllToolNodes() {
            let arr = [];
            for (let n of this.graphNodes) {
                let hideNode = n.source.includes("caseData") ? !this.toggleCaseData : true;
                if (hideNode) {
                    arr.push(n.id);
                }
            }
            this.highlightedNodes = this.highlightedNodes.filter((n) => !arr.includes(n));
            await this.chart.hide(arr);
            // window.networkx.body.data.nodes.update(arr);
        },

        addGraphFilter() {
            // if (this.possibleSearchTypes.length === 0) return;
            // if (this.getFilterValues.length === 0) return;
            const filterLength = this.graphFilters.length;
            if (filterLength === 0 || (this.graphFilters[filterLength - 1].filterVal !== "" && filterLength >= -1)) {
                this.graphFilters.push({
                    filterVal: "",
                    filterKey: null,
                    expanded: true,
                    id: uniqueId("filter_"),
                });
            }
        },
        async onToggleCaseData() {
            this.toggleCaseData = !this.toggleCaseData;
            if (this.toggleCaseData) {
                if (this.casedata) {
                    let nodesToShow = [];
                    this.graphNodes.forEach((node) => {
                        if (node.source.includes["caseData"]) {
                            // node.hidden = false;
                            this.highlightedNodes.push(node.id);
                            nodesToShow.push({id: node.id, hidden: false});
                        }
                    });
                    await this.chart.show(
                        nodesToShow.map((e) => e.id),
                        true
                    );
                    await this.executeFilter();
                } else {
                    this.casedata = true;
                    this.$emit("getCaseData");
                }
            } else {
                let nodesToHide = [];
                this.graphNodes.forEach((node) => {
                    let isOnlyCaseNode = node.source.length === 1 && node.source.includes("caseData");

                    if (isOnlyCaseNode) {
                        nodesToHide.push({id: node.id, hidden: true});
                        const idx = this.highlightedNodes.indexOf(node.id);
                        this.highlightedNodes.splice(idx, 1);
                    }
                });
                await this.chart.hide(nodesToHide.map((e) => e.id));
                await this.executeFilter();
            }
        },
        async onToggleToolData() {
            this.toggleToolData = !this.toggleToolData;
            const filters = this.toolType ? this.toolType.map((n) => n.label) : [];
            let nodesToUpdate = [];
            if (this.toggleToolData) {
                for (const node of this.graphNodes) {
                    const showNode = node.source.length === 1 && node.source.includes("caseData");
                    if (showNode) {
                        for (let s of node.source) {
                            if (filters.includes(s)) node.hidden = false;
                            nodesToUpdate.push({id: node.id, hidden: false});
                        }
                    }
                }
                await this.chart.show(
                    nodesToUpdate.map((e) => e.id),
                    true
                );
            } else {
                for (const node of this.graphNodes) {
                    const hodeNode = node.source.includes("caseData") ? !this.toggleCaseData : true;
                    if (hodeNode) {
                        node.hidden = true;
                        nodesToUpdate.push({id: node.id, hidden: true});
                    }
                }
                await this.chart.hide(nodesToUpdate.map((e) => e.id));
            }

            await this.executeFilter();
        },

        async clearExtraInfo(accumulator) {
            await this.chart.animateProperties(accumulator, {time: 0});
        },

        /**
         * This is the main function that is executed when a filter is
         * applied/removed
         */
        async executeFilter() {
            console.time("executeFilter");
            this.showLoader = true;
            // let toolNodes = this.getToolDataNodes();
            if (!this.isSomeFiltersApplied()) {
                await this.resetHighAllNodes();
                if (this.toggleCaseData) {
                    await this.unhideCaseData();
                }
                if (this.toolType.length === 0) {
                    await this.hideAllToolNodes();
                }
            }
            if (this.toggleCaseData) {
                this.unhideCaseData();
            } else {
                await this.hideCaseData();
            }
            await this.resetHighlightedNodes();
            await this.clearExtraInfo(this.clearConnectionCount);
            await this.clearExtraInfo(this.clearDegree);
            // this.resetHighlightedEdges();
            /* Get all the filters applied & then run them in order */
            let tools = this.toolType;
            let entityType = this.possibleSearchTypes;
            let entityValuesFilters = this.graphFilters;
            let connectionCount = this.nodeConnectionCount;

            if (this.possibleSearchTypes.length === 0) {
                this.graphFilters = [];
                this.nodeDegree = null;
            }
            if (tools.length >= 1 && this.toggleToolData) {
                await this.filterNodesByTool();

                // if (this.graphFilters.length > 0) {
                //     const tools = this.toolType.map((e) => e.label);
                //     const types = this.possibleSearchTypes.map((e) => e.label);
                //     let idxs = [];
                //     this.graphFilters.forEach((e, idx) => {
                //         if (!tools.includes(e.filterVal.source) && !types.includes(e.filterVal.type)) {
                //             idxs.push(idx);
                //         }
                //     });

                //     idxs.forEach((idx) => {
                //         this.graphFilters.splice(idx, 1);
                //     });
                // }
            }

            if (entityType.length > 0) {
                if (tools.length === 0) {
                    //   /* If no tools selected, but there could be some type filter selected,
                    //   so we need to unhide appropriate nodes (tools/casedata/both) */
                    await this.unhide();
                }
                // const filtersToKeep = this.possibleSearchTypes.map((e) => e.label);
                // Change again value filters accordingly.
                // let valueFiltersToRemove = [];
                // this.graphFilters.forEach((e) => {
                //     if (!filtersToKeep.includes(e.filterVal.type)) {
                //         valueFiltersToRemove.push(e.id);
                //     }
                // });

                // this.graphFilters = this.graphFilters.filter((f) => !valueFiltersToRemove.includes(f.id));
                await this.filterBySearchType();
                if (entityValuesFilters.length > 0 && entityValuesFilters.find((evf) => evf.filterVal !== "")) {
                    await this.filterGraph();
                }
            }
            if (connectionCount !== null) {
                if (tools.length === 0) {
                    //   /* If no tools selected, but there could be some type filter selected,
                    //   so we need to unhide appropriate nodes (tools/casedata/both) */
                    await this.unhide();
                }
                await this.getNodesByConnectionCount();
            }
            if (this.nodeDegree) {
                await this.filterByConnetionDegree();
            }
            this.showLoader = false;
            console.timeEnd("executeFilter");
        },

        async filterNodesByTool() {
            // this.highlightedNodes = [];
            // var arr = [];
            let nodesTohide = [];
            let nodesToShow = [];
            // var config = { hidden: true };
            // var show = { hidden: false, opactity: 1.0 };
            const filters = this.toolType.map((n) => n.label);
            for (let n of this.graphNodes) {
                const isOnlyCaseNode = n.source.length === 1 && n.source.includes("caseData");
                if (!isOnlyCaseNode) {
                    let sourceFound = false;
                    for (let s of n.source) {
                        if (filters.includes(s)) {
                            sourceFound = true;
                            if (this.highlightedNodes.indexOf(n.id) < 0) {
                                this.highlightedNodes.push(n.id);
                            }
                            nodesToShow.push(n.id);
                        }
                    }
                    if (!sourceFound) {
                        let hideNode = n.source.includes("caseData") ? !this.toggleCaseData : true;
                        if (hideNode) {
                            nodesTohide.push(n.id);
                        }
                    }
                }
            }
            this.highlightedNodes = this.highlightedNodes.filter((n) => !nodesTohide.includes(n));
            await this.chart.hide(nodesTohide);
            await this.chart.show(nodesToShow, true); // true -> show links connected to given nodes
        },

        /* Filter the nodes on graph by search Type,
ex. email, phone , address...
fade down all non-matches & highlight with circle all matches
*/
        async filterBySearchType() {
            // Reset all faded nodes
            var reset_arr = [];
            var resetFadedNodeConfig = {bg: false};

            let nodesSet = [];

            if (this.toolType.length > 0) {
                nodesSet = this.highlightedNodes;
            } else {
                /* Since search type filter is a child filter of (casdata/ tooldata top level filter) ,
                we need to tak ethis in to consideration.
                If we dont have any tool type filter selected , that means we dont
                have any highlighted nodes active on canvas. We need to consider all nodes in graph, but make sure to
                get nodes filtered by the parent filters (Case Data |  tool data | Both)
                */
                if (this.toggleCaseData) {
                    nodesSet = nodesSet.concat(this.getCaseDataNodes());
                }

                if (this.toggleToolData) {
                    nodesSet = nodesSet.concat(this.getToolDataNodes());
                }
                nodesSet = nodesSet.map((e) => e.id);
            }
            for (let n of this.highlightedNodes) {
                var obj = {};
                Object.assign(obj, resetFadedNodeConfig);
                obj.id = n;
                reset_arr.push(obj);
                // reset_arr.push(n.id);
            }
            await this.chart.setProperties(reset_arr);
            // window.networkx.body.data.nodes.update(reset_arr);
            if (this.possibleSearchTypes.length === 0) {
                // this.graphFilters = [];
                // this.clearShortestLinkHighlight()
                return;
            }

            const searchTypes = this.possibleSearchTypes.map((e) => e.label);
            let arr = [];
            for (let node of nodesSet) {
                const n_type = this.getNodeAttrById(node, "nodeType");
                if (searchTypes.includes(n_type)) {
                    arr.push(node);
                }
            }
            await this.highlightNodes(arr);
            this.filteredNodes = [...arr];
            this.valuesFromfilters["typeFilter"] = arr;
            // this.highlightedNodes = arr

            // this.$emit('FilterAppliedEvent')
        },

        async highlightNodes(nodes) {
            let nodesProp = {};
            this.graphNodes.forEach((el) => {
                nodesProp[el.id] = nodes.includes(el.id);
            });
            await this.chart.foreground((node) => {
                return nodesProp[node.id];
            });
        },
        async emptySearchTypeFilter() {
            this.possibleSearchTypes = [];
            this.graphFilters = [];
            // this.clearShortestLinkHighlight();
            let nodesToUpdate = [];
            for (const node of this.graphNodes) {
                node.hidden = true;
                nodesToUpdate.push({id: node.id, hi: true});
            }
            this.chart.setProperties(nodesToUpdate);
            // window.networkx.body.data.nodes.update(nodesToUpdate);
            await this.executeFilter();
            // this.resetAllFilters()
        },
        collapseAllFilters() {
            this.collapseAll = !this.collapseAll;
            this.graphFilters.forEach((e) => (e.expanded = !e.expanded));
        },
        async onRemoveFilter(index) {
            this.graphFilters.splice(index, 1);
            await this.filterGraph();
            if (this.graphFilters.length === 0) {
                await this.emptySearchTypeFilter();
            }
            this.possibleSearchTypes.splice(index, 1);
            await this.executeFilter();
        },
        shouldCollapseValueFilter(filter) {
            return this.collapseAll === true && filter.expanded === false ? true : false;
        },
        async onFilterValueSelect(index, data) {
            this.graphFilters[index]["filterKey"] = data.filterKey;
            this.graphFilters[index]["filterVal"] = data.filterVal;
            this.graphFilters[index]["expanded"] = true;
            await this.executeFilter();
        },

        async filterGraph() {
            const filters = this.graphFilters;

            var keep = [];
            if (filters.length >= 1) {
                var connectedNodes = [];
                for (let filter of filters) {
                    if (filter.filterVal !== "") {
                        const nodeId = filter.filterVal.id;
                        keep.push(nodeId);
                        connectedNodes = this.chart.graph().neighbours(nodeId)["nodes"];
                        keep = keep.concat(connectedNodes);
                    }
                }
                await this.highlightNodes(keep);

                //NOTE: check if it can be cached.

                // this.visibleNodes = keep;
            } else {
                await this.executeFilter();
                // this.clearShortestLinkHighlight();
            }

            this.highlightedNodes = this.highlightedNodes.concat(keep);
            this.filteredNodes = keep;
            // this.filteredNodes

            // this.$emit('FilterAppliedEvent')
        },
        async emptyToolTypeFilter() {
            let nodesToUpdate = [];
            this.toolType = [];
            this.graphFilters = [];
            await this.emptySearchTypeFilter();
            await this.executeFilter();
        },
        async getNodesByConnectionCount() {
            var arr = [];
            var connections_from_arr = [];
            let nodesSet = [];
            // const nodesSet = [...this.highlightedNodes, ...this.filteredNodes]

            /* When we have  type filter applied we want to find connection only in that,
            otherwise all highlighted nodes. */

            if (this.possibleSearchTypes.length === 0 && this.toolType.length === 0) {
                if (this.toggleCaseData) {
                    nodesSet = nodesSet.concat(this.getCaseDataNodes());
                }
                if (this.toggleToolData) {
                    nodesSet = nodesSet.concat(this.getToolDataNodes());
                }
                nodesSet = nodesSet.map((e) => e.id);
            } else {
                if (this.possibleSearchTypes.length > 0) {
                    nodesSet = this.filteredNodes;
                } else if (this.highlightedNodes.length > 0) {
                    nodesSet = this.highlightedNodes;
                }
            }
            const connectionCount = this.nodeConnectionCount.id;
            for (const nid of nodesSet) {
                let nodes = this.chart.graph().neighbours(nid, {all: true})["nodes"];

                // if (this.nodeConnectionCount === '10+') {
                //   if (nodes.length >= 10) {
                //     arr = arr.concat([...nodes])
                //     connections_from_arr.push(nid)
                //   }
                // }
                nodes = nodes.filter((n) => {
                    // return nodesSet.includes(n);
                    return [...this.highlightedNodes, ...nodesSet].includes(n);
                });
                if (nodes.length >= connectionCount) {
                    // arr = arr.concat([...nodes]);
                    connections_from_arr.push(nid);
                }
            }
            arr = [...new Set(arr)];
            // await this.highlightNodes([...arr, ...connections_from_arr]);
            await this.highlightNodes(connections_from_arr);
            await this.dashed_highlight_nodes(connections_from_arr);
        },

        async dashed_highlight_nodes(nodes) {
            var config = {
                e: 1.8,
                bw: 2,
                ci: true,
                b: "#FE4C4C",
            };

            var arr = [];
            let centerNodes = this.graphNodes.filter((el) => el.type === "multi_query_center_node").map((el) => el.id);
            for (let n of nodes) {
                if (!centerNodes.includes(n)) {
                    var obj = {};
                    Object.assign(obj, config);
                    obj.id = n;
                    arr.push(obj);
                    this.clearConnectionCount.push({id: n, e: 1, ci: false, b: "#fff", bw: 0});
                }
            }
            await this.chart.animateProperties(arr, {time: 0});
        },

        emptyShortestLinkFields() {
            this.shortestPathNodeOne = null;
            this.shortestPathNodeTwo = null;
            this.applyStoredFilters();
            // this.clearAnimatedPath();
        },
        async applyStoredFilters() {
            await this.clearAnimatedPath();
            this.shortestPathNodeOne = null;
            this.shortestPathNodeTwo = null;
            await this.clearShortestLinkHighlight();
            await this.executeFilter();
        },
        async clearShortestLinkHighlight() {
            // this.possibleSearchTypes = [];

            var arr = [];
            let config = null;
            for (let n of this.highlightedNodes) {
                var obj = {};
                Object.assign(obj, config);
                obj.id = n;
                arr.push(obj);
            }
            await this.chart.show(
                arr.map((e) => e.id),
                true
            );
            // If entity type filterrs were applied we would want to remove those also.

            if (this.graphFilters.length >= 1) {
                // this.removeEntityTypeFilters();
            }
        },

        shortestLink() {
            if (this.shortestPathNodeOne && this.shortestPathNodeTwo) {
                // this.findShortestLink();
                let start = this.shortestPathNodeOne.id;
                let dest = this.shortestPathNodeTwo.id;
                this.selectionChanged(start, dest);
                // const filters = this.resetAllFilters();
                // this.storedFilters = filters;
                // this.showFiltersRestoreButton = true;
            }
        },

        async clearAnimatedPath(path) {
            await this.chart.animateProperties(this.clearAnimation, {time: 0});
        },

        animateShortestPath(path, weighting) {
            path.onePath.forEach((item, index) => {
                if (index % 2 === 0) {
                    // item is a node, so update border & enlargement
                    this.chart.animateProperties({id: item, e: 1.9, b: colours["time"], bw: 4}, {time: 0});
                    this.clearAnimation.push({id: item, e: 1, b: "#ffffff", bw: 0});
                } else {
                    // item is a link, so update colour and width
                    // this.chart.animateProperties({ id: item, c: colours["time"], w: 5 }, { time: 0 });
                    this.chart.setProperties({id: item, c: colours["time"], w: 5});
                    this.clearAnimation.push({id: item, c: "#808080", w: 1});
                }
            });
        },

        selectionChanged(node1, node2) {
            this.chart.setProperties(this.clearAnimation);
            this.clearAnimation = [];
            // if we have 2 path ends, calculate the path and animate it
            if (node1 && node2) {
                if (node1 === node2) return;
                let filters;
                // if(this.savedFilters)
                //     filters = this.savedFilters
                // else filters = this.resetAllFilters();
                // this.storedFilters = filters;
                this.showFiltersRestoreButton = true;

                let attrs = this.chart.getItem([node1, node2]);
                this.shortestPathNodeOne = {id: node1, label: attrs[0].t};
                this.shortestPathNodeTwo = {id: node2, label: attrs[1].t};

                const options = {direction: "any"};
                // if (pathWeighting !== 'equal') options.value = pathWeighting;
                const path = this.chart.graph().shortestPaths(node1, node2, options);
                this.chart.foreground((node) => {
                    if (this.highlightedNodes.includes(node.id) && !path.one.includes(node.id)) {
                        return false;
                    } else if (path.one.includes(node.id)) {
                        return true;
                    }
                });
                this.animateShortestPath(path, "equal");
                // updateSideInfo((path.onePath.length - 1) / 2, path.distance);
            }
        },

        selectNode({id}) {
            const resetSelection = () => {
                this.chart.selection({});
                this.pathEnds = [null, null];
                this.selectionChanged();
                // updateSideInfo();
            };
            const validatedItem = this.chart.getItem(id);
            if (validatedItem && validatedItem.type === "node") {
                // if user clicked a node, store it as a path end
                this.pathEnds = [this.pathEnds[1], null];
                this.pathEnds[1] = id;
                // if we have 2 ends, give them to the selection change handler
                if (this.pathEnds[0] !== null) this.selectionChanged(this.pathEnds[0], this.pathEnds[1]);
            } else if (id === null || validatedItem) {
                // user clicked the background or a link
                resetSelection();
            }
        },
        // resetAnalyzerOptions() {
        // },
        initialiseInteractions() {
            this.chart.on("context-menu", ({id}) => {
                this.showSmartAnalyser = true;
                const node = this.chart.getItem(id);
                var viewCoords = this.chart.viewCoordinates(node.x, node.y);
                var fakeStartButton = this.$refs["analyze-popup"]["$el"];
                fakeStartButton.style.position = "absolute";
                fakeStartButton.style.left = `${viewCoords.x + 300}px`;
                fakeStartButton.style.top = `${viewCoords.y}px`;
                this.selectedValue = node.label;
                // this.selectedSource = (node.nodeType === 'link') ?  'url' ? (node.nodeType === 'company')  : node.nodeType
                // if(node.nodeType === 'link') {
                //     this.selectedSource = 'url'
                // }
                // else if(node.nodeType === 'company')  {
                //     this.selectedSource = 'company_name'
                // }
                if (node.nodeType === "link") {
                    this.selectedSource = "url";
                } else if (node.nodeType === "company" || node.nodeType === "current_employer" || node.nodeType === "Company Name" || node.nodeType === "Current Employer") {
                    this.selectedSource = "company_name";
                } else if (node.nodeType === "number") {
                    this.selectedSource = "phone";
                } else if (node.nodeType === "nickname" || node.nodeType === "full_name" || node.nodeType === "query" || node.nodeType === "val_type" || node.nodeType === "user" || node.nodeType === "person" || node.nodeType === "Search engine query") {
                    this.selectedSource = "name";
                }
                // else if(node.nodeType === ('username'))  {
                //     this.selectedSource = 'name'
                // }
                else {
                    this.selectedSource = node.nodeType.toLowerCase();
                }

                // console.log(selectedSource, "")
                this.$modal.show("analyzer", {src: this.selectedSource, value: this.selectedValue, fromGraph: true});

                // this.$refs["analyze-popup"]?.$refs["analyze-tippy"]?.click();
                // this.$refs["analyze-popup"].$el.click();
                // e.preventDefault();
                // this.resetAnalyzerOptions()
            });
            this.chart.on("click", this.selectNode);
            this.chart.on("progress", ({task, progress}) => {
                if (task === "layout") {
                    // use progress value for something
                    if (progress === 1) {
                        this.layoutDone = true;
                    }
                }
            });

            // prevent users selecting more than one node
            this.chart.on("selection-change", () => {
                if (this.chart.selection()[1]) this.chart.selection([]);
            });
            this.chart.on("hover", (hoverObject) => {
                if (hoverObject.id) {
                    this.handleHover(hoverObject);
                } else {
                    this.handleHoverRemoved(hoverObject);
                }
            });
        },
        handleHover(hoverObject) {
            if (this.chart.getItem(hoverObject.id)["type"] === "link") {
                this.onLinkHover(hoverObject.id);
            }
        },
        handleHoverRemoved(hoverObject) {
            this.onLinkHoverRemoved();
        },
        onLinkHover(linkId) {
            this.onLinkHoverRemoved();
            this.hoveredLink = linkId;
            const linkDetails = this.data.items.find((el) => el.id === linkId);
            this.chart.setProperties({id: linkId, t: linkDetails.title});
        },
        onLinkHoverRemoved() {
            if (this.hoveredLink) {
                this.chart.setProperties({id: this.hoveredLink, t: ""});
                this.hoveredLink = null;
            }
        },
        imageMap() {
            this.iconMap = {
                followers: require("@/assets/icons/graph/user.png"),
                username: require("@/assets/icons/graph/user.png"),
                Name: require("@/assets/icons/graph/user.png"),
                name: require("@/assets/icons/graph/user.png"),
                full_name: require("@/assets/icons/graph/user.png"),
                Name: require("@/assets/icons/graph/user.png"),
                company: require("@/assets/icons/ico-office.svg"),
                "Company Name": require("@/assets/icons/ico-office.svg"),
                current_employer: require("@/assets/icons/graph/job.svg"),
                "Current Employer": require("@/assets/icons/graph/job.svg"),
                current_title: require("@/assets/icons/graph/employees.svg"),
                phone: require("@/assets/icons/graph/phone-call.png"),
                contact: require("@/assets/icons/graph/phone-call.png"),
                email: require("@/assets/icons/graph/email.png"),
                location: require("@/assets/icons/graph/location.png"),
                address: require("@/assets/icons/graph/address.svg"),
                city: require("@/assets/icons/graph/location.png"),
                country: require("@/assets/icons/graph/location.png"),
                state: require("@/assets/icons/graph/location.png"),
                default: require("@/assets/icons/graph/default.png"),
                registrant_address: require("@/assets/icons/graph/location.png"),
                registrant_city: require("@/assets/icons/graph/location.png"),
                registrant_country: require("@/assets/icons/graph/location.png"),
                registrant_email: require("@/assets/icons/graph/email.png"),
                registrant_name: require("@/assets/icons/graph/user.png"),
                registrant_phone: require("@/assets/icons/graph/phone-call.png"),
                registrant_state: require("@/assets/icons/graph/location.png"),
                domain: require("@/assets/icons/graph/domain.svg"),
                // multi_query_center_node:require("@/assets/icons/graph/search-query.svg")
                // domain: this.iconCache['domain'],
                // registrant_org: this.iconCache['organisation_name'],
                link: require("@/assets/icons/graph/web-link.png"),
                url: require("@/assets/icons/graph/web-link.png"),
                generated_uri: require("@/assets/icons/graph/web-link.png"),
                instagram: require("@/assets/icons/social-medias/instagram.svg"),
                twitter: require("@/assets/icons/social-medias/twitter.svg"),
                github: require("@/assets/icons/social-medias/github.svg"),
                gitlab: require("@/assets/icons/social-medias/gitlab.svg"),
                linkedin: require("@/assets/icons/social-medias/linkedin.svg"),
                multi_query_center_node: require("@/assets/icons/graph/ico-search-query.svg"),
                subject: require("@/assets/icons/graph/subject.svg"),
                education: require("@/assets/icons/graph/education.svg"),
                company_name: require("@/assets/icons/ico-office.svg"),
                Name: require("@/assets/icons/graph/user.png"),
                "Company Name": require("@/assets/icons/ico-office.svg"),
                url: require("@/assets/icons/graph/web-link.png"),
            };
        },

        //           case "name":
        //               config = { image: require("@/assets/icons/graph/user.png"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "current_employer":
        //               config = { image: require("@/assets/icons/graph/job.svg"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "current_title":
        //               config = { image: require("@/assets/icons/graph/employees.svg"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "phone":
        //           case "contact":
        //               config = { image: require("@/assets/icons/graph/phone-call.png"), size: 25, font: { color: "#38314C" } };

        //               break;
        //           case "email":
        //               config = { image: require("@/assets/icons/graph/email.png"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "location":
        //           case "city":
        //           case "country":
        //           case "state":
        //               config = { image: require("@/assets/icons/graph/location.png"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "followers":
        //               config = { image: "https://w7.pngwing.com/pngs/858/581/png-transparent-profile-icon-user-computer-icons-system-chinese-wind-title-column-miscellaneous-service-logo.png", size: 25, font: { color: "#38314C" } };
        //               break;
        //           default: {
        //               // let iconFound = false;
        //               // icons.forEach(iconDoc => {
        //               //   if (type === iconDoc.key) {
        //               //     config = { image: iconDoc.icon_data, size: 30, font: { color: '#6B45C6' } };
        //               //     iconFound = true;
        //               //   }
        //               // });
        //               if (this.iconCache[type] !== undefined) {
        //                   config = { image: this.iconCache[type], size: 25, font: { color: "#38314C" } };
        //               } else {
        //                   config = { image: require("@/assets/icons/graph/default.png"), size: 25, font: { color: "#38314C" } };
        //               }
        //               // if (!iconFound)
        //               //   config = { image: require("@/assets/icons/graph/default.png"), size: 25, font: { color: '#38314C' } };
        //           }

        // getImageForNodes(node) {
        //       // let icons = [...this.$store.getters.getIconsData, ...this.$store.getters.getSocialPlatforms];
        //       let type = node.type;
        //       let config = null;
        //       switch (type) {
        //           case "multi_query_center_node":
        //               config = { shape: "box", color: { font: "black" } };
        //               break;
        //           case "username_search_key":
        //               config = { image: require("@/assets/icons/graph/user_1.png"), shape: "image", size: 30, font: { color: "#6B45C6" } };
        //               break;
        //           case "followers":
        //               config = { image: require("@/assets/icons/graph/user.png"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "username":
        //           case "name":
        //               config = { image: require("@/assets/icons/graph/user.png"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "current_employer":
        //               config = { image: require("@/assets/icons/graph/job.svg"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "current_title":
        //               config = { image: require("@/assets/icons/graph/employees.svg"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "phone":
        //           case "contact":
        //               config = { image: require("@/assets/icons/graph/phone-call.png"), size: 25, font: { color: "#38314C" } };

        //               break;
        //           case "email":
        //               config = { image: require("@/assets/icons/graph/email.png"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "location":
        //           case "city":
        //           case "country":
        //           case "state":
        //               config = { image: require("@/assets/icons/graph/location.png"), size: 25, font: { color: "#38314C" } };
        //               break;
        //           case "followers":
        //               config = { image: "https://w7.pngwing.com/pngs/858/581/png-transparent-profile-icon-user-computer-icons-system-chinese-wind-title-column-miscellaneous-service-logo.png", size: 25, font: { color: "#38314C" } };
        //               break;
        //           default: {
        //               // let iconFound = false;
        //               // icons.forEach(iconDoc => {
        //               //   if (type === iconDoc.key) {
        //               //     config = { image: iconDoc.icon_data, size: 30, font: { color: '#6B45C6' } };
        //               //     iconFound = true;
        //               //   }
        //               // });
        //               if (this.iconCache[type] !== undefined) {
        //                   config = { image: this.iconCache[type], size: 25, font: { color: "#38314C" } };
        //               } else {
        //                   config = { image: require("@/assets/icons/graph/default.png"), size: 25, font: { color: "#38314C" } };
        //               }
        //               // if (!iconFound)
        //               //   config = { image: require("@/assets/icons/graph/default.png"), size: 25, font: { color: '#38314C' } };
        //           }
        //       }
        //       if (node.isSubQueryNode === true) {
        //           config.shape = "image";
        //           config.borderWidth = 3;
        //       }
        //       return config;
        //   },
        async filterByConnetionDegree() {
            let degreeCount = this.nodeDegree;
            const outdegrees = this.chart.graph().degrees();
            let arr = [];
            if (this.possibleSearchTypes.length > 0) {
                arr = this.filteredNodes;
            } else {
                arr = this.highlightedNodes;
            }
            var config = {
                e: 1.8,
                bw: 2,
                ci: true,
                b: "#FE4C4C",
            };
            var _extraInfo = [];
            arr.forEach((n) => {
                var obj = {};
                Object.assign(obj, config);
                obj.id = n;
                _extraInfo.push(obj);
                this.clearDegree.push({id: n, e: 1, ci: false, b: "#fff", bw: 0});
            });
            await this.chart.animateProperties(_extraInfo, {time: 10});
            let nodesToRemove = [];
            while (degreeCount >= 1) {
                let _arr = [];
                for (let i = 0; i < arr.length; i++) {
                    let connectedNodes = this.chart.graph().neighbours(arr[i])["nodes"];
                    connectedNodes.forEach((n) => {
                        if (!arr.includes(n)) _arr.push(n);
                    });
                }
                degreeCount--;
                arr = arr.concat(_arr);
            }
            arr = arr.filter((n) => !nodesToRemove.includes(n));
            arr = [...new Set(arr)];
            await this.chart.foreground((node) => {
                return arr.includes(node.id);
            });
        },
        addImagesToNodes(nodes) {
            // NOTE : some old graphs were saved, reset those styles temporarily & fix them later on.

            nodes.forEach((node) => {
                if (node.type !== "multi_query_center_node") node.shape = "circularImage";

                const attrs = this.getImageForNodes(node);
                if (attrs.shape) {
                    node.shape = attrs.shape;
                }
                if (attrs.font) {
                    node.font = attrs.font;
                }
                if (attrs.size) {
                    node.size = attrs.size;
                }
                // node.color = {};
                // node.color.background = "#fff";
                node.image = attrs.image;

                if (attrs !== undefined && node.type !== "multi_query_center_node" && !node.isSubQueryNode) {
                    node.color = "#fff";
                    node.borderWidth = 0;
                    // node.shape = "image"
                }

                // if (node.isSubQueryNode === true) {
                //   node.shape = "image";
                //   node.borderWidth = 3;
                // }
            });

            // return nodes
        },
        klReady(chart) {
            this.chart = chart;
            this.chart.options({handMode: true});
            this.initialiseInteractions();
            this.$emit("KLgraphReady");

            // // Attach an event handler to all the chart events
            // chart.on('all', (evt) => {
            //     // Update the active modifier keys
            //     if (evt.event && evt.event.modifierKeys) {
            //     Object.keys(evt.event.modifierKeys).forEach((key) => {
            //         if (evt.event.modifierKeys[key]) {
            //         document.getElementById(key).classList.add('active');
            //         } else {
            //         document.getElementById(key).classList.remove('active');
            //         }
            //     });
            //     }
            //     if (onEvents.get(evt.name)) {
            //     // Add this event to the log
            //     const eventObject = {};
            //     if (evt.event) {
            //         Object.keys(evt.event).forEach((prop) => {
            //         if (prop !== 'defaultPrevented') eventObject[prop] = evt.event[prop];
            //         });
            //     }
            //     const lastEvt = eventDetails[eventCounter++];
            //     eventDetails[eventCounter] = { name: evt.name, event: eventObject };
            //     // Check if a repeat of the previous event
            //     if (eventObject && lastEvt && evt.name === lastEvt.name
            //         && eventObject.id === lastEvt.event.id) {
            //         // Group it with the previous event and up the counter for that event
            //         const td = document.getElementsByClassName('eventname')[0];
            //         const rowData = td.parentElement.dataset;
            //         const countSpan = td.children[0];
            //         countSpan.innerText = `(${eventCounter - rowData.firstId + 1})`;
            //         rowData.lastId = eventCounter;
            //     } else {
            //         // Add it to the top of the event log
            //         const tr = document.createElement('tr');
            //         tr.innerHTML = `<td id='${eventCounter}' class='eventname'>'${evt.name}'<span></span></td>`;
            //         tr.dataset.firstId = eventCounter;
            //         tr.dataset.lastId = eventCounter;
            //         tr.onclick = onRowClick;
            //         eventsTable.insertBefore(tr, eventsTable.firstChild);
            //     }
            //     // Ensure the top row is highlighed
            //     highlightRow(document.getElementsByClassName('eventname')[0].parentElement);
            //     // Update the displayed event details to show this event
            //     displayEvent(eventCounter);
            //     // Unhide the clear button
            //     clearButton.classList.remove('hide');
            //     }
            // });
        },

        updateNodes(nodes) {
            if (nodes.length > 0) {
                let new_nodes = [
                    ...nodes.map((e) => {
                        let ico;
                        let nodeSize = 1;
                        if (e.isCaseData) {
                            if (e.type === "subject") {
                                ico = this.iconMap["subject"];
                            } else {
                                ico = this.iconMap[e.type];
                            }
                        } else {
                            // ico = this.iconMap[e.type];
                            if (e.isQueryNode && !e.isGlyphNode) {
                                ico = this.iconMap["multi_query_center_node"];
                            } else {
                                ico = this.iconMap[e.type];
                            }
                        }
                        if (e.flag && e.type === "location") {
                            try {
                                e.flag_code = e.flag_code == "uk" ? "gb" : e.flag_code;
                                ico = require(`@/assets/icons/flags/${e.flag_code.toLowerCase()}.svg`);
                            } catch (error) {
                                let country_name = JURISDICTION_MAP.find((ele) => ele.jurisdiction.code == e.flag_code.toLowerCase() && !ele.flag_code)?.jurisdiction?.full_name;
                                if (country_name) {
                                    let flag_code = JURISDICTION_MAP.find((ele) => ele.jurisdiction.full_name == country_name && ele.jurisdiction.code !== e.flag_code.toLowerCase());
                                    if (flag_code?.jurisdiction?.code) {
                                        try {
                                            ico = require(`@/assets/icons/flags/${flag_code?.jurisdiction?.code.toLowerCase()}.svg`);
                                        } catch (error) {}
                                    }
                                }
                            }
                        }

                        if (ico === undefined) ico = this.iconMap["default"];
                        if (e.type === "multi_query_center_node") {
                            nodeSize = 1.8;
                        }

                        if (e.label === undefined || e.label === null) {
                            e.label = "[No Label]";
                        }

                        let label = e.label;

                        if (label.length > 50) {
                            label = `${label.slice(0, 50)}...`;
                        }

                        if (this.nodeTypeKeyMap[e.type] !== undefined) {
                            e.type = this.nodeTypeKeyMap[e.type];
                            e.searchType = this.nodeTypeKeyMap[e.type];
                        }

                        let nodeConf = {...e, nodeType: e.type, type: "node", t: label, u: ico, e: nodeSize};

                        if (e.isGlyphNode) {
                            nodeConf["g"] = [
                                {
                                    u: this.iconMap["multi_query_center_node"],
                                    p: 45,
                                    e: 1.5,
                                    r: 50,
                                },
                            ];
                        }
                        return nodeConf;
                    }),
                ];
                if (this.toggleCaseData) {
                    for (let n of new_nodes) {
                        if (n.isCaseData === true) {
                            this.highlightedNodes.push(n.id);
                        }
                    }
                }

                // let new_nodes = [];

                // for(let n of nodes){
                //   if(n.isCaseData){

                //   }
                // }
                // this.addImagesToNodes(new_nodes);
                // let existingNodes = this.data.items.filter((el) => el.type === "node").map((n) => n.id);
                // let newNodes = new_nodes.filter((el) => !existingNodes.includes(el.id));

                // setTimeout(this.addNodeAfterInitialiseGraph, 100, new_nodes);

                // setTimeout(() => {
                // this.chart.layout('organic')
                //     // this.saveGraphData();
                // }, 2000);

                // window.networkx.body.data.nodes.update(new_nodes);
                // window.networkx.redraw();
                // this.data.items.push(...newNodes);
                this.chart.merge(new_nodes).then(async () => {
                    await this.executeFilter();
                    if (this.isGraphDataQueueEmpty) {
                        await this.StabilizeGraph();
                    }
                });
                // if (newNodes.length > 0) {
                // }
            } else {
                // window.networkx.setData({ nodes: [], edges: [] });
            }
        },
        updateLinks(links) {
            let new_links = [
                ...links.map((e, index) => {
                    return {type: "link", id: e.id, id1: e.from, id2: e.to, a1: true, w: 1, title: e.title};
                }),
            ];

            let existingLinks = this.data.items.filter((el) => el.type === "link").map((n) => n.id);
            let newLinks = new_links.filter((el) => !existingLinks.includes(el.id));
            if (newLinks.length > 0) {
                this.data.items.push(...newLinks);
                this.chart.merge(newLinks).then(() => {
                    // this.StabilizeGraph();
                });
                // setTimeout(this.addLinksAfterInitialiseGraph, 100, new_links);

                //  setTimeout(() => {
                //     this.chart.layout('organic')
                //         // this.saveGraphData();
                //     }, 2000);

                //     // window.networkx.body.data.edges.update(links);
                // window.networkx.redraw();
            }
        },
    },
    data() {
        return {
            activeTypeFilters: [],
            showSmartAnalyser: false,
            storedFilters: {},
            savedFilters: null,
            layoutDone: false,
            hoveredLink: null,
            collapseAll: false,
            stabilizing: false,
            showFiltersRestoreButton: false,
            tabilizing: false,
            showLoader: false,
            toggleToolData: false,
            toggleCaseData: false,
            possibleSearchTypes: [],
            toolType: [],
            visibleNodes: [],
            nodeDegree: null,
            highlightedNodes: [],
            filteredNodes: [],
            persistToolFilters: [],
            nodeConnectionCount: null,
            graphFilters: [],
            valuesFromfilters: {
                typeFilter: [],
            },

            shortestPathNodeOne: null,
            shortestPathNodeTwo: null,
            iconMap: {},
            clearAnimation: [],
            clearConnectionCount: [],
            clearDegree: [],
            pathEnds: [null, null],
            data: {
                type: "LinkChart",
                items: [],
            },
            keylineData: {
                type: "LinkChart",
                items: [],
            },
            nodeDegreeOptions: [
                {
                    group: "Connection Degree",
                    categories: [1, 2, 3, 4],
                },
            ],
            connectionCountOptions: [
                {
                    group: "Connection Count",
                    categories: [
                        {id: 1, label: "1+"},
                        {id: 2, label: "2+"},
                        {id: 3, label: "3+"},
                        {id: 4, label: "4+"},
                        {id: 5, label: "5+"},
                        {id: 6, label: "6+"},
                        {id: 7, label: "7+"},
                        {id: 8, label: "8+"},
                        {id: 9, label: "9+"},
                        {id: 10, label: "10+"},
                    ],
                },
            ],
            selectedSource: "",
            selectedValue: "",
        };
    },
};
</script>
<style src="./graph.scss" scoped lang="scss"></style>
<style>
html {
    box-sizing: border-box;
}

.bg-drop {
    font-size: 16px;
    color: cornflowerblue;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
}

.klchart {
    width: 100vw;
    height: 100vh;
}
</style>
